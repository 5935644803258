/*
Documentation

toggleAlert params

1 - boolean
this is either true or false. True will show the alert false will hide it

2 - type
one of: success, danger, warning, info

3 - text
this is either a react component or a string of text to show in the alert

4 - time
the time in miliseconds to keep the alert up
default is 5000
if is string "infinite" the alert will never go away

*/

import * as actionTypes from '../../actions';
import store from 'store';

export const setOnlineStatus = (bool) => {
    store.dispatch({ type: actionTypes.SET_ONLINE_STATUS, payload: bool, });
}


export const toggleStandardLoader = (boolean) => {
    store.dispatch({ type: actionTypes.TOGGLE_STANDARD_LOADER, payload: boolean, });
}

export const toggleAlertBS = (error, text) => {
    store.dispatch({ type: actionTypes.TOGGLE_SYSTEM_ALERT_BS, payload: { error, text } });
}
