/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

// import { setViewingUser } from 'store/functions/auth/auth';
import { setOnlineStatus } from 'store/functions/system/system';
import stackLogger from 'database/stackLogger';

const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);

    // gets access to internet, not fired on load
    window.addEventListener('online', () => setOnlineStatus(true));
    window.addEventListener('offline', () => setOnlineStatus(false));

    let errors = 0;

    const tryLoad = async (tries = 0) => {
        if(!errors) return resolve(true);
    }

    tryLoad();


    window.onerror = function(msg, file, line, col, e) {
        stackLogger(e);
    };

})

export default init