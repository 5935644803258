/*
Documentation

all dispatch actions should be declared in this file and used

this prevents mis-typing action names as it will through an error
by either the browser or IDE

*/


export const SET_VIEWING_USER             = 'SET_VIEWING_USER';
export const TOGGLE_SYSTEM_ALERT_BS       = 'TOGGLE_SYSTEM_ALERT_BS';
export const TOGGLE_STANDARD_LOADER       = 'TOGGLE_STANDARD_LOADER';
export const SET_ONLINE_STATUS            = 'SET_ONLINE_STATUS';
export const SET_SETTINGS                 = 'SET_SETTINGS';