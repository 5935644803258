/*
Documentation

a standard loader that drops a small black div down and cannot be clicked through
Drops a circle loading directly into the middle of the page.

called by the following

import { toggleStandardLoader } from 'store/functions/system/system'

toggleStandardLoader(true)      \ turn on loader
... execute code to wait on
toggleStandardLoader(false)     | turn off loader

*/

import React, { Component } from 'react';
import Circle from "components/markup/loading/Circle";
import { connect } from 'react-redux';

class StandardLoader extends Component {


    state = {
        standardLoader: this.props.standardLoader,
        removeNoScroll: true
    };

    componentWillReceiveProps = (nextProps) => {

        this.setState({standardLoader: nextProps.standardLoader})
        if(nextProps.standardLoader) {

            // if the document should not scroll before starting this make sure it doesn't scroll after removing the loader
            this.setState({removeNoScroll: document.body.classList.value.includes('noScroll') ? false : true})
            document.body.classList.add('noScroll')

        } else {

            if(this.state.removeNoScroll) {

                document.body.classList.remove('noScroll')

            }
        }

    }


    render() {
        if(this.state.standardLoader) {

            return (
                <div
                    id="AosFader"
                    className="top-0 bottom-0 right-0 left-0 position-fixed d-flex"
                    style={{zIndex: 9999999999999, background: 'rgba(0,0,0,.5)'}}
                >
                    <div className="align-self-center ml-auto mr-auto">
                        <Circle />
                    </div>
                </div>
            )

        } else {

            return <div></div>

        }

    }

}

const mapStateToProps = state => {
    return {
        standardLoader: state.system.standardLoader,
    };
};

export default connect(mapStateToProps, '')(StandardLoader);
